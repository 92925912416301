<style type="text/css">
@import "~@/assets/css/common.css";
@import "~@/assets/css/admin.css";
</style>
<style type="text/css" scoped>
  /deep/ .el-form-item__content {
    line-height: inherit;
  }
  /deep/.el-pagination__jump {
    margin-left: 0;
    margin-right: 10px;
  }
  .queryForm .el-select,.queryForm .el-input{
    width: 150px !important;
  }
  .check-distri {
    line-height: 32px;
  }
  .el-tag{
    cursor: pointer;
  }
  /deep/ .el-dialog__header {
    background: rgb(197 197 197 / 40%) !important;
    padding: 20px;
    text-align: left !important;
  }
  .el-form-item {
    margin-bottom: 22px;
  }
  /deep/ .el-form-item__label {
    margin-bottom: 0 !important;
  }
 table /deep/.el-form-item {
    margin-bottom: 10px;
  }
  .check-item /deep/ .el-radio {
    margin-top: 15px;
  }
  .check-item /deep/ .el-form-item__label {
    width: 110px;
  }
  /deep/ .el-tag .el-radio {
    margin-top: 0;
  }

</style>
<script>
import Layout from "@/views/layouts/main";
import {checkFile, getUploadLimit, uploadFiles} from "@/api/common/public";
import {
  informationApplyList,
  editInformationApply,
  findUser,
  informationApplyDetail,
  informationApplyAddCheck,
  informationApplyDelCheck,
  informationApplyCheck,
  submitFile,
  findUserByKey,
  slrRefuce
} from "@/api/admin/information/informationCollaboration.js";
import CheckFlow from "../../../../components/information-check-flow.vue";

/**
 * Dashboard component
 */
export default {
  components: {
    Layout,
    CheckFlow
  },
  data() {
    return {
      query: {
        keyword: '',
        lzzt: ''
      },
      uploadModule: 'Information',
      uploadLimit:{},
      tableList: [],
      allDataList: [],

      pageData: {
        pageNum: 1,
        pageSize: 100,
        total: 0
      },
      showDetail: false,
      dialogTitle: '',
      form: {
        key: '',
        title: '',
        sqr: '',
        sqsx: '',
        szdw: '',
        lxr: '',
        lxdh: '',
        sqsj: '',
        slr: '',
        lzzt: '',
        info: {},
        checkList: [],
        addNext: 0,
        sjts: 0
      },
      options: [],
      loading: false,
      rules: {
        sqsx: [{ required: true, message: '请输入申请事项', trigger: 'blur' },
          { max: 100, message: '长度在 100 个字符以内', trigger: 'blur' }
        ],
        sqyt: [{ required: true, message: '请输入申请用途', trigger: 'blur' },
          { max: 100, message: '长度在 100 个字符以内', trigger: 'blur' }
        ], 
        szdw: [{ required: true, message: '请输入所在单位', trigger: 'blur' }],
        lxr: [{ required: true, message: '请输入联系人', trigger: 'blur' }],
        lxdh: [{ required: true, message: '请输入联系电话', trigger: 'blur' }], 
      },
    };
  },
  
  watch: {
    'form.jbrzh'(newVal) {
      const selectedUser = this.options.find(option => option.yhzh === newVal);
      if (selectedUser) {
        this.form.jbr = selectedUser.xm;
      }
    }
  },
  computed: {
      checkSlr() {
          // 受理人
          let e = this.form.checkList.filter(item => item.shzh ===this.$store.state.userInfo.yhzh)[0]||{};
          return this.form.lzzt == 1 && ((e && e.shjb == 0 && e.shzh)||(this.$store.state.userInfo.userType?.substring(9, 10) == "1"));
      },
      checkJbr() {
          // 经办人
          return this.form.jbrzh ==this.$store.state.userInfo.yhzh && this.form.lzzt >= 4;
      },
      checkShr() {
          // 先确认未审核当前审核级别
          let l = this.form.checkList.filter(item => item.checkId && item.shzt == 0 && item.shjb != 0 && item.shjb != 100)||[];
          if(l.length == 0) {
              return false||this.checkCxsh;
          }
          let shjb = l[0].shjb;
          // 审核人
          let list = this.form.checkList.filter(item => item.shzh ===this.$store.state.userInfo.yhzh && item.shjb == shjb)||[]
          if(list.length == 0) {
              return false||this.checkCxsh;
          }
          let e = list[0];
          this.form.shr = e;
          this.shztHandel();
          return (this.form.lzzt >= 2 && e && e.shzh)||this.checkCxsh;
      },
      // 判断能否再次重新审核
      checkCxsh() {
        // 获取当前用户的审核级别
        let list = this.form.checkList.filter(item => item.checkId && item.shzh ===this.$store.state.userInfo.yhzh)||[];
        if(list.length == 0) {
          return false;
        }
        let shjb = list[list.length-1].shjb;
        
        // 判断下一级有没有已审核的，没有就可以重新审核
        let l = this.form.checkList.filter(item =>   item.shjb == (shjb+1) && item.shzt != 0)||[];
        if(l.length != 0 || shjb == 100 || shjb == 0) {
          return false;
        }
        // 审核人
        this.form.shr = list[list.length-1];
        return true;
      },
  },
  methods: {
    // 获取数据列表
    getList() {
      informationApplyList({...this.query, ...this.pageData}).then(res => {
        if (res.status) {
          this.tableList = res.data
          this.pageData.total = res.total
        }
      })
    }, 
    handleSizeChange(val) {
      this.pageData.pageSize = val
      this.getList();
    },
    handleCurrentChange(val) {
      this.pageData.pageNum = val
      this.getList();
    },
    shzt(level, val){
      if(level == 0 || level == 100){
        switch(val) {
          case '0':
            return '<span style="color: #FFA500;">待处理</span>'
          case '1':
            return '<span style="color: #1E90FF;">已受理</span>'
        }
      } else{
        switch(val) {
          case '0':
            return '<span style="color: #FFA500;">待审核</span>'
          case '1':
            return '<span style="color: #1E90FF;">通过</span>'
          case '2': 
            return '<span style="color: #FF0000;">不通过</span>'
        }
      }
    },
    lzzt(val){
      switch(val) {
        case '0':
          return '<span style="color: #FFA500;">待提交</span>'
        case '1':
          return '<span style="color: #1E90FF;">已提交</span>'
        case '2':
          return '<span style="color: #32CD32;">已受理</span>'
        case '3':
          return '<span style="color: #FF0000;">不通过</span>'
        case '4':
          return '<span style="color: #9370DB;">已审批</span>'
        case '5':
          return '<span style="color: #008000;">已完成</span>'
        default:
          return ''
      }
    },
    submitForm() {
      // 判断附件是否上传
      if (!this.form.fjdz) {
        this.$message.warning('请上传附件');
        return;
      }
      
      // 判断受理人是否填写
      if (!this.form.slr) {
        this.$message.warning('请填写受理人');
        return;
      }
      this.$refs.form.validate((valid) => {
        if (valid) {
          // 表单验证通过,执行提交操作
          editInformationApply(this.form).then(res => {
            if (res.status) {
              this.$message.success('操作成功');
              this.showModal = false;
              this.getList();
            }
          });
        }
      });
    },
    limit() {
      getUploadLimit({
        module: this.uploadModule,
      }).then(res => {
        if (res.status) {
          this.uploadLimit["ext"] = "." + res.data.extLimit.replaceAll(",", ",.");
          this.uploadLimit["size"] = res.data.sizeLimit;
        }
      });
    },
    uploadFile(file) {
      /** 上传背景文件 */
      var _this = this;
      file = file.raw;
      if (checkFile(_this, this.uploadLimit.size, this.uploadLimit.ext, file)) {
        _this.uploadStatus = "loading";
        uploadFiles(_this.uploadModule, file).then(res => {
          if (res.status) {
            var file = res.data[0];
            if (file.status) {
              this.form.sjwjmc = file.name.substring(0, 100);
              this.form.sjwjdz = file.fileId;
              this.form.sjwjmm = file.password;
              _this.$message({
                type: "success",
                message: "上传成功"
              });
              this.fileUp = true
            } else {
              _this.$message({
                type: "error",
                message: file.error
              });
            }
          }
          _this.uploadStatus = "";
        });
      }
    },
    openFile(fileId) {
      window.open(this.getFileUrl(fileId, false), "_blank");
    },
    detailInfo(applyId) {
      this.showDetail = true;
      informationApplyDetail(applyId).then(res => {
        if(res.status) {
          this.form = { ...res.data.info, checkList: res.data.checkList, level: res.data.level };
          let e = res.data.checkList.filter(item => item.shzh ===this.$store.state.userInfo.yhzh)[0]||{};
          if (e.shzh) { 
            this.$set(this.form, 'shzt', e.shzt+'');
            this.$set(this.form, 'shsm', e.shsm);
          }
          if(this.form.jbrzh) {
            this.options = [{ xm: this.form.jbr, yhzh: this.form.jbrzh, yddh: '' }];
          }
        }
      });
    },
    addUser(level, type=''){
      if(!this.form.yddhStr) {
        return;
      }
      findUser({yddh: this.form.yddhStr, type: 'admin'}).then(res => {
        if(res.status) {
            if(type == 'jbr') {
              let data = res.data[0];
              this.form.jbr = data?.xm;
              this.form.jbrzh = data?.yhzh;
              this.$forceUpdate();
              return;
            } 
          res.data = res.data.map(m => {
            return {infoId: this.form.infoId||null, shzh: m.yhzh, shxm: m.xm, shzt: '0',
                ...this.form.level.filter(item => item.shjb == level)[0]
            }
          })
          this.form.checkList = [...this.form.checkList||[], ...res.data]
          this.form.checkList = this.form.checkList.filter((item, index, self) =>
                  index === self.findIndex(t => (
                      t.shzh === item.shzh && item.shjb == t.shjb
                  ))
          );
          // 如果没有负责人默认一个
          if(!this.form.checkList.filter(e => e.shjb==level && e.fzr == 1).length) {
            for (let item of this.form.checkList) {
                if (item.shjb == level) {
                    item.fzr = 1;
                    break;
                }
            }
          }
          this.$forceUpdate();
        }
      })
    },
    checkRefuce() {
      this.$confirm(`是否确认拒绝受理?`, "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
        }).then(() => {
          slrRefuce({applyId: this.form.applyId}).then(res => {
              if(res.status) {
                this.showDetail = false;
                this.getList();
              }
          }); 
        }).catch(()=>{});
    },
    addCheck(){
      let form = {
        ...this.form, checkList: this.form.checkList.filter(item => item.shjb != 100 && item.shjb != 0)
      };
      if(!form.checkList.length) {
        this.$message.warning('请选择转发'+this.form.level.filter(item => item.shjb == 1 )[0]?.jbmc);
        return;
      }
      if(form.sjts == 0) {
        this.$message.warning('请输入数据条数');
        return;
      }
      if(this.form.lzzt == 1) {
        this.$confirm(`是否确认上报，提交后无法修改?`, "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
        }).then(() => {
          informationApplyAddCheck(form).then(res => {
              if(res.status) {
                this.detailInfo(form.applyId);
              }
          });
        }).catch(()=>{});
      } else {
        informationApplyAddCheck(form).then(res => {
              if(res.status) {
                this.detailInfo(form.applyId);
              }
          });
      }
    },
    handleFzrChange(changedUser) {
      // 将其他用户的 fzr 设置为 0
      this.form.checkList.forEach(user => {
        if(changedUser.shjb == user.shjb) {
            if (user.shzh != changedUser.shzh ) {
                this.$set(user, 'fzr', 0);
            } else {
                this.$set(user, 'fzr', 1);
            }
        }
      });
    },
    delCheck(item) { 
      if(!item.checkId) {
        this.form.checkList = this.form.checkList.filter(e => !(e.shjb == item.shjb && e.shzh == item.shzh));
          // 如果没有负责人默认一个
          // console.log(this.form.checkList.filter(e => e.shjb==item.shjb && e.fzr == 1));
        if(!this.form.checkList.filter(e => e.shjb==item.shjb && e.fzr == 1).length) {
            for (let e of this.form.checkList) {
                if (e.shjb == item.shjb) {
                    this.$set(e, 'fzr', 1);
                    break;
                }
            }
        }
        return;
      }
      informationApplyDelCheck(item.checkId).then(res => {
        if(res.status) {
            this.form.checkList = this.form.checkList.filter(e => e.checkId != item.checkId);
          // 如果没有负责人默认一个
          if(!this.form.checkList.filter(e => e.shjb==item.shjb && e.fzr == 1).length) {
            for (let e of this.form.checkList) {
                if (e.shjb == item.shjb) {
                    e.fzr = 1;
                    break;
                }
            }
            this.addCheck();
          }
        }
      })
    },
    level() {
      return this.form.level.filter(item => item.shjb == this.form.shr.shjb+1 && this.form.shr.fzr == 1)[0]?.jbmc;
    },
    applyCheck() {
      // 判断审核状态、审核说明不为空
      if (this.form.shzt == 0) {
        this.$message.warning('请选择审核状态');
        return;
      }
      if (!this.form.shsm) {
        this.$message.warning('请填写审核说明');
        return;
      }
      let nextCheck = this.form.checkList.filter(e => e.shjb > this.form.shr.shjb && e.shjb != 100 && e.shjb != 0)||[];   
      if (this.level()) {
        if (!this.form.addNext && this.form.shzt == 1) {
          this.$message.warning('请选择下一步');
          return;
        }
        // 下一级审核
        if (this.form.addNext == 1 && nextCheck.length == 0) {
          this.$message.warning('请选择下一级审核人');
          return;
        }
        // 选择经办人
        if (this.form.shr.shjb == 1 && this.form.shzt == 1 && this.form.shr.fzr == 1 && !this.form.jbrzh) {
          this.$message.warning('请选择经办人');
          return;
        }
      }
      // 是否当前审核级别最后一个
      let last = this.form.checkList.filter(e => e.checkId != this.form.shr.checkId && e.shzt == '0' && e.shjb == this.form.shr.shjb && e.shjb != 100 && e.shjb != 0).length == 0;
      let form = { ...this.form.shr, shzt: this.form.shzt, shsm: this.form.shsm, last: last, 
        checkList: nextCheck,
        slr: this.form.slr, slrzh: this.form.slrzh, sqsx: this.form.sqsx,
        jbr: this.form.jbr, jbrzh: this.form.jbrzh,
        sqr: this.form.sqr, sqrzh: this.form.sqrzh
      };
       // 设置经办人
       if (this.form.shr.shjb == 1 && this.form.shzt == 1 && this.form.shr.fzr == 1) {
        form.jbr = this.form.jbr;
        form.jbrzh = this.form.jbrzh;
       }
      informationApplyCheck(form).then(res => {
        if(res.status) {
          this.$message.success('审核提交成功');
          this.showDetail = false;
          this.getList();
        }
      })
    },
    jbrSubmit() {
        if(!this.form.sjwjdz) {
            this.$message.warning('请先上传文件');
            return;
        }    

      this.$confirm(`是否确认提交?`, "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
        }).then(() => {
            submitFile(this.form).then(res => {
                if(res.status) {
                  this.$message.success('提交成功');
                  this.showDetail = false;
                  this.getList();
                }
            })
        });     
    },
    remoteSearch(query) {
      if (query.length > 1) {
        this.loading = true;
        findUserByKey({key: query}).then(res => {
          this.loading = false;
          if(res.status) {
            this.options = res.data;
          } else {
            this.$message.warning('未查询到受理人信息');
            this.options = [];
          }
        }).catch(() => {
          this.loading = false;
        });
      } else {
        this.options = [];
      }
    },
    shztHandel() {
      this.form.shsm = this.form.shzt=='1'&& !this.form.shsm?'同意':this.form.shsm
    }
  },
  mounted() {
    this.getList();
    this.limit();
  }

};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="min-height: 600px;">
          <div class="card-body">
            <div class="pb-3 border-dash check-table-top">
              <div class="flexList ">
                <el-select v-model="query.lzzt" class="mr-2" placeholder="请选择流转状态" size="small">
                  <el-option label="选择流转状态" value=""></el-option>
                  <el-option label="已提交待受理" :value="1"></el-option>
                  <el-option label="已受理待审批" :value="2"></el-option>
                  <el-option label="已审批待办理" :value="4"></el-option>
                  <el-option label="已完成" :value="5"></el-option>
                  <el-option label="已拒绝" :value="3"></el-option>
                </el-select>
                <el-input class=" mr-2 " placeholder="查询申请人/申请事项" style="width: 180px;"  v-model="query.keyword" size="small" maxlength="50" >
                </el-input>
                <button type="button" class="btn btn-info h30 flexList mr-2" @click="getList"><i
                    class="iconfont mr-2 icon-mb-search"></i>查询</button>
                    <span style="color: blue;">
                      当前待审批记录: {{ tableList.filter(f => {return (!f.shzt ||f.shzt == 0 )&& '3,5'.indexOf(f.lzzt)<0;}).length }}
                    </span>

              </div>
            </div>
            <div class="table-responsive border mt-3">
              <table class="table  light-table table-hover ">
                <thead class="thead-light">
                <tr>

                  <th style="width: 80px;">
                    <input type="checkbox" id="all" name="all"  /> 序号</th>
                  <th style="width: 150px;">申请人</th>
                  <th >申请事项</th>
                  <th style="width: 150px;">所在单位</th>
                  <th style="width: 120px;">联系人</th>
                  <th style="width: 120px;">联系电话</th>
                  <th style="width: 150px;">申请时间</th>
                  <th style="width: 120px;">受理人</th>
                  <th style="width: 120px;">流转状态</th>
                  <th style="width: 120px;">操作</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(obj,index) in tableList" :key="index">
                  <td>

                    {{index+1+(pageData.pageNum-1)*pageData.pageSize}}
                  </td>
                  <td>{{obj.sqr}}</td>
                  <td>{{obj.sqsx}}</td>
                  <td> {{obj.szdw}}</td>
                  <td> {{obj.lxr}}</td>
                  <td>{{obj.lxdh}}</td>
                  <td> {{obj.sqsj}}</td>
                  <td> {{obj.slr}}</td>
                  <td v-html="lzzt(obj.lzzt)"></td>
                  <td>
                    <el-button type="text" size="small" @click="detailInfo(obj.applyId)">{{ obj.shzt == '1' || '3,5'.indexOf(obj.lzzt)>=0?'查看':'审批' }}</el-button>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <div class="float-right d-flex ">
              <el-pagination @size-change="handleSizeChange" background @current-change="handleCurrentChange"
                             :current-page.sync="pageData.pageNum" :page-size="pageData.pageSize"
                             layout="total, sizes,jumper, prev, pager, next" :total="pageData.total">
              </el-pagination>
            </div>

          </div>
        </div>
      </div>


    </div>
    <!-- 添加-->
     
    <el-dialog 
      :visible.sync="showDetail" 
      :close-on-click-modal="false" 
      title="流转详情" 
      width="50%"
      center>
      <div >
        <div style="max-height: 55vh; overflow-y: auto;">
            <table style="width: 100%; table-layout: fixed; border-collapse: collapse;">
                <tr>
                    <td style="width: 10%; padding: 10px; border-bottom: 1px solid #eee; font-weight: bold;">申&ensp;请&ensp;人</td>
                    <td style="width: 40%;padding: 10px; border-bottom: 1px solid #eee;" >{{ form.sqr }}</td>
                    <td style="width: 10%; padding: 10px; border-bottom: 1px solid #eee; font-weight: bold;">所在单位</td>
                    <td style="width: 40%;padding: 10px; border-bottom: 1px solid #eee;" >{{ form.szdw }}</td>
                </tr>
                <tr>
                    <td style="width: 10%; padding: 10px; border-bottom: 1px solid #eee; font-weight: bold;">联&ensp;系&ensp;人</td>
                    <td style="width: 40%; padding: 10px; border-bottom: 1px solid #eee;">{{ form.lxr }}</td>
                    <td style="width: 10%; padding: 10px; border-bottom: 1px solid #eee; font-weight: bold;">联系电话</td>
                    <td style="width: 40%; padding: 10px; border-bottom: 1px solid #eee;">{{ form.lxdh }}</td>
                </tr>
                <tr>
                    <td style="padding: 10px; border-bottom: 1px solid #eee; font-weight: bold;">申请事项</td>
                    <td style="padding: 10px; border-bottom: 1px solid #eee;">{{form.sqsx}}</td>
                    <td style="padding: 10px; border-bottom: 1px solid #eee; font-weight: bold;">所属考试</td>
                    <td style="padding: 10px; border-bottom: 1px solid #eee;">{{form.ssks}}</td>
                </tr>
                <tr>
                    <td style=" padding: 10px; border-bottom: 1px solid #eee; font-weight: bold;">申请用途</td>
                    <td style="padding: 10px; border-bottom: 1px solid #eee;">{{ form.sqyt }}</td>
                    <td style=" padding: 10px; border-bottom: 1px solid #eee; font-weight: bold;">申请格式</td>
                    <td style="padding: 10px; border-bottom: 1px solid #eee;">{{ form.sqgs }}</td>
                </tr>
                <tr>
                    <td style=" padding: 10px; border-bottom: 1px solid #eee; font-weight: bold;">申请附件</td>
                    <td style="padding: 10px; border-bottom: 1px solid #eee;" colspan="3">
                        <a style="line-height: 36px;" v-if="form.fjdz" @click="openFile(form.fjdz)"  href="javascript:void(0);">{{ form.fjmc }}</a> 
                    </td>
                </tr>
                <tr>
                    <td style="width: 10%; padding: 10px; border-bottom: 1px solid #eee; font-weight: bold;">流转详情</td>
                    <td style="max-width: 90%;padding: 10px; border-bottom: 1px solid #eee;" colspan="3">
                        <div style="overflow-x: auto;max-width: 100%;">
                          <CheckFlow :check-list="form.checkList" :info="form" :type="'check'" />
                        </div>
                    </td>
                </tr>
            </table>
        </div>  
        <table style="width: 100%; border-collapse: collapse;">
            <colgroup>
                <col style="width: 10%;">
                <col style="width: 90%;">   
            </colgroup>
            <tr v-if="checkSlr">
            <td style="width: 10%;padding: 10px; border-bottom: 1px solid #eee; font-weight: bold;">报上级审批</td>
            <td style="padding: 10px; border-bottom: 1px solid #eee;" colspan="3">
                <el-form>
                    <el-form-item label="添加部门领导：">
                        <el-input v-model="form.yddhStr" type="textarea" maxlength="1000" style="width: 60%;" placeholder="输入手机号码/姓名，多个用英文逗号分开"></el-input>
                        <el-button size="mini" style="margin-left: 10px;" @click="addUser(1)"><i class="iconfont mr-1 el-icon-user"></i>添加用户</el-button>
                    </el-form-item>
                    <el-form-item label="已选部门领导：">
                        <div v-if="form.checkList&&form.checkList.length">
                            <el-tag 
                                v-for="(user, index) in form.checkList.filter(item => item.shjb != 100 && item.shjb != 0)"
                                :key="index"
                                class="mb-2 mr-2"
                                type="success"
                                closable
                                @close="delCheck(user)"
                                @click="handleFzrChange(user)"
                            >
                                <el-radio v-model="user.fzr" :label="1" :value="1" @change="handleFzrChange(user)">
                                    {{ user.shxm }}
                                </el-radio>
                            </el-tag>
                        </div>
                    </el-form-item>
                    <el-form-item label="是否接收数据 ：">
                      <el-switch style="margin-top: 10px;" size="mini" v-model="form.slrjssj"
                       :active-value="'1'"
                        :inactive-value="'0'"
                      ></el-switch>
                    </el-form-item>
                    <el-form-item label=" 数 据 条 数 ：">
                      <el-input-number 
                          class="ml-3"
                          v-model="form.sjts" 
                          size="mini"
                          :min="0" 
                          placeholder="请输入数据条数">
                        </el-input-number>
                    </el-form-item>
                </el-form>
            </td>
          </tr>
          <tr v-if="checkShr">
            <td style="padding: 10px; border-bottom: 1px solid #eee; font-weight: bold;">审核处理</td>
            <td style="padding: 10px; border-bottom: 1px solid #eee;" colspan="3">
                <el-form class="check-item" >
                    <el-form-item label="审核状态：">
                      <el-radio-group v-model="form.shzt" style="line-height: 40px;" @change="shztHandel">
                        <el-radio :label="'1'">同意</el-radio>
                        <el-radio :label="'2'">拒绝</el-radio>
                      </el-radio-group>
                    </el-form-item>
                    <el-form-item label="审核说明 ：">
                        <el-input v-model="form.shsm" type="textarea" maxlength="100" style="width: 60%;"></el-input>
                    </el-form-item>
                    <template v-if="level()">
                      
                      <template v-if="form.shr.shjb == 1 && form.shr.fzr == 1">
                        <el-form-item label="添加经办人：" >
                              <el-select
                                v-model="form.jbrzh"
                                filterable
                                remote
                                reserve-keyword
                                placeholder="请输入姓名或手机搜索"
                                :remote-method="remoteSearch"
                                :loading="loading">
                                <el-option
                                  v-for="item in options"
                                  :key="item.yhzh"
                                  :label="item.xm +(item.yddh?'（手机：'+item.yddh+'）':'')"
                                  :value="item.yhzh">
                                </el-option>
                              </el-select>
                              <span style="color: #909399; font-size: 12px;margin-left: 10px;">(请输入姓名或手机搜索)</span>
                            </el-form-item>
                        </template>
                        <el-form-item label="请选择下一步：" v-if="form.shr.fzr == 1" >
                            <el-radio-group v-model="form.addNext">
                                <el-radio :label="1">报{{level()}}</el-radio>
                                <el-radio :label="2">终审</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <template v-if="form.addNext == 1">
                            <el-form-item :label="'添加'+level()+'：'">
                            <el-input v-model="form.yddhStr" type="textarea" maxlength="1000" style="width: 60%;" placeholder="输入手机号码，多个用英文逗号分开"></el-input>
                            <el-button size="mini" style="margin-left: 10px;" @click="addUser(form.shr.shjb+1)"><i class="iconfont mr-1 el-icon-user"></i>添加{{ level() }}</el-button>
                            </el-form-item>
                            <el-form-item :label="'已选'+level()+'：'">
                                <div v-if="form.checkList.length">
                                    <el-tag
                                    v-for="(user, index) in form.checkList.filter(item => item.shjb == form.shr.shjb+1)"
                                    :key="index"
                                        class="mb-2 mr-2"
                                        type="success"
                                        closable
                                        @close="delCheck(user)"
                                        @click="handleFzrChange(user)"
                                    >
                                        <el-radio v-model="user.fzr" :label="1" :value="1" @change="handleFzrChange(user)">
                                                    {{ user.shxm }}
                                                </el-radio>
                                        </el-tag>
                                </div>
                            </el-form-item>
                        </template>
                     
                    </template>
                    
                </el-form>
            </td>
          </tr>
          <tr v-if="checkJbr">
            <td style="padding: 10px; border-bottom: 1px solid #eee; font-weight: bold;">上传数据</td>
            <td style="padding: 10px; border-bottom: 1px solid #eee;line-height: 40px;;" colspan="3">
                <a style="line-height: 36px;" v-if="form.sjwjdz" @click="openFile(form.sjwjdz)"  href="javascript:void(0);">{{ form.sjwjmc }}</a> 
        
                <el-upload class="upload-demo" style="width: 100%;text-align: left;" action="" :auto-upload="false" :on-change="uploadFile" size="small"
                        :accept="uploadLimit.ext" :show-file-list="false">
                <el-button size="mini" plain style="display: inline;"><i
                    class="iconfont mr-1 icon-shangchuan"></i>上传文件</el-button>
                <el-tag type="danger" class=" ml-2" style="height: 30px;">
                    支持上传{{ uploadLimit.ext }}文件，且不超过{{
                    uploadLimit.size / 1024 / 1024
                    }}M</el-tag>
                </el-upload>
            </td>
          </tr>
          <tr v-else-if="form.lzzt == 5">
            <td style="padding: 10px; border-bottom: 1px solid #eee; font-weight: bold;">上传数据</td>
            <td style="padding: 10px; border-bottom: 1px solid #eee;line-height: 40px;;" colspan="3">
              <a style="line-height: 36px;" v-if="form.sjwjdz" @click="openFile(form.sjwjdz)"  href="javascript:void(0);">{{ form.sjwjmc }}</a> 
              （数据条数：{{ form.sjts }}）
            </td>
          </tr>
        </table>
       
      </div>
      <span slot="footer" class="dialog-footer">
        <template  v-if="checkSlr">
          <el-button type="danger" @click="checkRefuce">拒绝受理</el-button>
          <el-button type="primary" @click="addCheck">上报</el-button>
        </template> 
        <template  v-if="checkShr">
          <el-button type="primary" @click="applyCheck">提交审核</el-button>
        </template> 
        <template  v-if="checkJbr">
          <el-button type="primary" @click="jbrSubmit">确认提交上传文件</el-button>
        </template>
        <el-button @click="showDetail = false">取 消</el-button>
      </span>
      </el-dialog>
  </Layout>
</template>
